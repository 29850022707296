<template>
  <div id="home">
    <b-navbar sticky toggleable="lg" id="nav">
      <div class="mx-auto text-center">
        <b-navbar-brand to="/#home" id="logo" class="my-4 m-0"
          ><b-img
            :src="require(`@/assets/svg/bamboo.svg`)"
            fluid
            center
            alt="Logo"
          ></b-img
        ></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      </div>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto text-center">
          <b-nav-item to="/#home">Home</b-nav-item>
          <b-nav-item to="/#preise">Preise</b-nav-item>
          <b-nav-item to="/#buchen">Buchen</b-nav-item>
          <b-nav-item to="/#massagen">Massagen</b-nav-item>
          <b-nav-item to="/impressum">Impressum</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view></router-view>
    <b-container fluid id="footer" class="p-5">
      <b-container>
        <b-row>
          <b-col>
            <h2 class="mb-4">Bamboo Thaimassage</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <p>
              <b-icon
                icon="geo-alt-fill"
                class="mr-2 align-middle"
                font-scale="1.5"
              ></b-icon>
              Marienstraße 25, 33098 Paderborn
            </p>
            <p>
              <a href="tel:052518785050"
                ><b-icon
                  icon="telephone-fill"
                  class="mr-2 align-middle"
                  font-scale="1.5"
                ></b-icon>
                05251 878 50 50</a
              >
            </p>
          </b-col>
          <b-col lg="6">
            <div class="table-responsive-lg">
              <table class="table">
                <tbody>
                  <tr>
                    <td><router-link to="/#home">Home</router-link></td>
                    <td><router-link to="/#preise">Preise</router-link></td>
                    <td>
                      <router-link to="/datenschutz">Datenschutz</router-link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <router-link to="/#buchen">Termin buchen</router-link>
                    </td>
                    <td><router-link to="/#about">Über uns</router-link></td>
                    <td>
                      <router-link to="/impressum">Impressum</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="mx-auto text-center">
            <a href="https://www.instagram.com/thaimassagebamboo_paderborn/">
              <b-img
                :src="require(`@/assets/svg/instagram.svg`)"
                alt="Instagram"
                class="mx-3"
              ></b-img>
            </a>
            <a href="https://www.facebook.com/Thaimassagepaderborn/">
              <b-img
                :src="require(`@/assets/svg/facebook.svg`)"
                alt="Facebook"
                class="mx-3"
              ></b-img>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", function() {
        var navbar = document.getElementById("nav");
        var nav_classes = navbar.classList;
        if (document.documentElement.scrollTop >= 10) {
          if (nav_classes.contains("shrink") === false) {
            nav_classes.toggle("shrink");
          }
        } else {
          if (nav_classes.contains("shrink") === true) {
            nav_classes.toggle("shrink");
          }
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
nav {
  transition: all 0.5s;
  display: block;
  > div {
    position: relative;
    button {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  img {
    height: 100%;
  }
}

nav.shrink {
  box-shadow: 0 0.5rem 1rem rgba(#000, 0.15);
  display: flex;
  > div {
    position: static;
    .navbar-brand {
      margin-top: 0rem !important;
      margin-bottom: 0rem !important;
    }
    button {
      position: absolute;
      right: 16px;
      top: 8px;
    }
  }
  img {
    height: 50px;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/impressum",
    name: "Impressum",
    // route level code-splitting
    // this generates a separate chunk (impressum.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/Impressum.vue"),
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    // route level code-splitting
    // this generates a separate chunk (datenschutz.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "datenschutz" */ "../views/Datenschutz.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: { x: 0, y: 100 },
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
